<template>
    <section class="bg-light" id="
             
             
             ">
      <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="section-heading text-uppercase">Palmares</h2>
                <h3 class="section-subheading text-muted">Qui suis-je en image.</h3>
                <p>  Pratiquant les sports de combat depuis plus de 10 ans je d&#xE9;tiens les comp&#xE9;tences suivantes :</p>

                <p> - Champion r&#xE9;gional en Kickboxing</p>
                <p>- Champion r&#xE9;gional en Muay-Tha&#xEF;</p>
                <p> - Champion de la zone Sud-Ouest en Muay-Tha&#xEF;</p>
                <p>- Finaliste championnat de France en Muay-Tha&#xEF;</p>




</div>
        </div>
        <div class="row">
          
            <div class="col-md-4 col-sm-6 
                 
                 -item" v-for="(obj, key) in PalmaresJSON" :key="key">
                <a class="Palmares-link" data-toggle="modal" href="#PalmaresModal1">
                    <div class="Palmares-hover">
                        <div class="Palmares-hover-content">
                            <i class="fas fa-plus fa-3x"></i>
                        </div>
                    </div>
                    <img class="img-fluid" :src="obj.img" alt="">
                </a>
                <div class="Palmares-caption">
                    <h4>{{ obj.caption }}</h4>
                    <p class="text-muted">{{ obj.title }}</p>
                </div>
            </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    data: () => ({
        PalmaresJSON: [
            {
                img: require('@/assets/img/Jeremmedaille.jpg'),
                caption: '',
                title: ''
            },
            {
                img: require('@/assets/img/clarisse.jpg'),
                caption: '',
                title: ''
            },
            {
                img: require('@/assets/img/jeremdos.jpg'),
                caption: '',
                title: ''
            },
            {
                img: require('@/assets/img/Memet.jpg'),
                caption: '',
                title: ''
            },
            {
                img: require('@/assets/img/crono.jpg'),
                caption: '',
                title: ''
            },
            {
                img: require('@/assets/img/jeremc.jpg'),
                caption: '',
                title: '' 
            }
           
        ]
    })
}
</script>

<style lang="css">

</style>


