<template>
    <div id="app">

        <!-- Navigation -->
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div class="container">
                <a class="navbar-brand js-scroll-trigger" href="#page-top">Jeremy Soulet</a>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i class="fas fa-bars"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav text-uppercase ml-auto">
                        <li class="nav-item">
                            <a class="nav-link js-scroll-trigger" href="#Presentation">Presentation</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link js-scroll-trigger" href="#Palmares">Palmares</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link js-scroll-trigger" href="#Tarif">Tarif</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link js-scroll-trigger" href="#contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <!-- Header -->
        <header class="masthead">
            <div class="container">
                <div class="intro-text">
                    <div class="intro-lead-in">Bienvenue</div>
                    <div class="intro-heading text-uppercase">Envie de progresser en boxe</div>
                </div>
            </div>
        </header>

        <!-- Presentation -->
        <section id="Presentation">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="section-heading text-uppercase">Presentation</h2>
                        <h3 class="section-subheading text-muted">
                           
                            Coaching en sports de combat
                            Sp&eacute;cialit&eacute; boxe pieds-poings et boxe tha&#239;landaise
                        </h3>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-md-4">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-shopping-cart fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="service-heading">Qui suis-je ?</h4>
                        <p class="text-muted">Je suis Jeremy Soulet ,&#xE0; la suite de mon exp&eacute;rience en tant que pratiquant j&#039;ai d&eacute;cid&eacute; d&#039;orienter mon parcours vers un engagement p&eacute;dagogique et j&#039;ai d&eacute;cid&eacute; de passer de nombreux diplomes qualifiants </p>
                    </div>
                    <div class="col-md-4">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-laptop fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="service-heading">Mes qualifications</h4>
                        <p class="text-muted">
                            <br />  - Brevets de moniteurs f&eacute;d&eacute;raux en sports de contact (sp&eacute;cialit&eacute; BMF2 muay-tha&#239;)
                            <br />  - Brevet de juge-arbitre r&eacute;gional (BJAR)
                            <br />   - Diplom&eacute; du BPJEPS en sports de combat au CREPS de Montpellier
                            <br />   - Grade Prajeet marron en Muay Tha&#239; de la FFKMDA
                        </p>
                    </div>
                    <div class="col-md-4">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-lock fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="service-heading">Mes services</h4>
                        <p class="text-muted">
                            Actuellement coach en boxe tha&#239;landaise au club du CBU-Combat de Limoges je vous propose plusieurs prestations pour du coaching priv&eacute; ou en groupe :

                            <br />   - D&eacute;couverte ou initiation &#224; la boxe pieds-poings et &#224; la boxe Tha&#239;landaise
                            <br />   - Perfectionnement et am&eacute;lioration technique et physique en Muay Tha&#239;
                             <br />  - Remise en forme et travail cardio ludique en utilisant du mat&eacute;riel de frappe
                             <br />  - Evacuation du stress et travail sur la confiance en soi

                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Palmares Grid -->
        <section id="Palmares">
            <Palmares></Palmares>
        </section>

        <!-- Tarif -->
        

        <section id="Tarif">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="section-heading text-uppercase">Tarif</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <ul class="timeline">
                            <li>
                                <div class="timeline-image">
                                    <img class="rounded-circle img-fluid" src="img/Tarif/1.jpg" alt="">
                                </div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>
                                           
                                             D&eacute;couverte ou initiation &#224; la boxe pieds-poings et &#224; la boxe Tha&#239;landaise
                                        </h4>
                                        <h4 class="subheading">
                                            <br> <strong>Coaching personnel</strong><br>    <strong>Coaching en groupe</strong> (jusqu &#039;&#224; 4 personnes) : 40 euros la s&eacute;ance

                                        </h4>
                                    </div>
                                    <div class="timeline-body">
                                        <p class="text-muted">
                                            Vous souhaitez vous initier &#224; la boxe pied-poings et plus particuli&#xE8;rement la boxe tha&#239;landaise je m&#8217;adapte &#224; tout les niveaux et vous propose des s&eacute;ances sans risques pour d&eacute;couvrir cet univers !

                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-inverted">
                                <div class="timeline-image">
                                    <img class="rounded-circle img-fluid" src="img/Tarif/2.jpg" alt="">
                                </div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>Perfectionnement et am&eacute;lioration technique et physique en Muay Tha&#239;</h4>
                                        <h4 class="subheading">
                                            <br> <strong>Coaching personnel</strong> : 28euros la s&eacute;ance ou carnet de 4 s&eacute;ances (valable 2 mois) a 100euros

                                            <br>   <strong>Coaching en groupe</strong> (jusqu&#8217;&#224; 4 personnes) : 40 euros la s&eacute;ance
                                        </h4>
                                    </div>
                                    <div class="timeline-body">
                                        <p class="text-muted">
                                            Si vous pratiquez d&eacute;j&#224; et souhaitez vous am&eacute;liorer sur le plan technique et physique en vue d&#8217;objectifs tels la comp&eacute;tition o&#xF9; le simple d&eacute;passement de soi
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="timeline-image">
                                    <img class="rounded-circle img-fluid" src="img/Tarif/2.jpg" alt="">
                                </div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4> Remise en forme et travail cardio ludique en utilisant du mat&eacute;riel de frappe</h4>
                                        <h4 class="subheading">
                                            <br> <strong>Coaching personnel</strong> : 28euros la s&eacute;ance ou carnet de 4 s&eacute;ances (valable 2 mois) a 100euros

                                            <br>    <strong>Coaching en groupe</strong> (jusqu&#8217;&#224; 4 personnes) : 40 euros la s&eacute;ance
                                        </h4>
                                    </div>
                                    <div class="timeline-body">
                                        <p class="text-muted">Vous avez envie de vous remettre au sport o&#xF9; de vous entretenir mais le footing ne vous convient pas , venez travailler votre cardio et renforcer vos muscles de fa&#xE7;on plus ludique gr&#xE2;ce a des exercices de sport de combat ! </p>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-inverted">
                                <div class="timeline-image">
                                    <img class="rounded-circle img-fluid" src="img/Tarif/4.jpg" alt="">
                                </div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>Evacuation du stress et travail sur la confiance en soi</h4>
                                        <h4 class="subheading">
                                            <br> <strong>Coaching personnel</strong> : 28euros la s&eacute;ance ou carnet de 4 s&eacute;ances (valable 2 mois) a 100euros

                                            <br>   <strong>Coaching en groupe</strong> (jusqu&#8217;&#224; 4 personnes) : 40 euros la s&eacute;ance
                                        </h4>
                                    </div>
                                    <div class="timeline-body">

                                        <p class="text-muted">
                                            Vous sentez un besoin d&#8217;&eacute;vacuer le stress ou certains tracas de la vie,reprendre confiance en soi et en son corps, de nombreux exercices de frappe et un coaching adapt&eacute; peuvent vous y aider !


                                        </p>

                                    </div>
                                </div>
                            </li>
                            <li class="timeline-inverted">
                                <div class="timeline-image">
                                    <h4>
                                        Quand on veut on peut
                                        <br>quand on peut
                                        <br>on doit.
                                    </h4>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <!-- Contact -->
        <section id="contact">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="section-heading text-uppercase">Contactez moi</h2>
                        <p><font color="white">0624735165</font></p>
                        <p><font color="white">jeremy.soulet@gmail.com</font></p>
                    </div>
                </div>
                </div>
          
        </section>

        <!-- Footer -->
        <footer>
            <div class="container">
                <div class="row">
                    
                    <div class="col-md-4">
                        <ul class="list-inline social-buttons">
                            <li class="list-inline-item">
                                <a href="https://www.instagram.com/jeremy.fight/">
                                    <i class="fab fa-instagram"></i>
                                </a>
                            </li>
                           
                        </ul>
                    </div>

                </div>
            </div>
        </footer>

        <!--


    Modals -->
        <!-- Modal 1 -->
       
        <!-- Modal 6 -->
        <!--<div class="Palmares-modal modal fade" id="PalmaresModal6" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-dismiss="modal">
                        <div class="lr">
                            <div class="rl"></div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 mx-auto">
                                <div class="modal-body">-->
                                    <!-- Project Details Go Here -->
                                    <!--<h2 class="text-uppercase">Project Name</h2>
                                    <p class="item-intro text-muted">Lorem ipsum dolor sit amet consectetur.</p>
                                    <img class="img-fluid d-block mx-auto" src="img/jeremy1.jpg" alt="">
                                    <p>Use this area to describe your project. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est blanditiis dolorem culpa incidunt minus dignissimos deserunt repellat aperiam quasi sunt officia expedita beatae cupiditate, maiores repudiandae, nostrum, reiciendis facere nemo!</p>
                                    <ul class="list-inline">
                                        <li>Date: January 2017</li>
                                        <li>Client: Window</li>
                                        <li>Category: Photography</li>
                                    </ul>
                                    <button class="btn btn-primary" data-dismiss="modal" type="button">
                                        <i class="fas fa-times"></i>
                                        Close Project
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</template>

<script>

import Palmares from './components/Palmares'

export default {
  name: 'app',
  components: {
    Palmares
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background: black;
}
</style>
